// Shadowed 2020-09-10 - up to date
import React from 'react'
import PropTypes from 'prop-types'
import { graphql, Link } from 'gatsby' // Medeinos: Added Link
import queryString from "query-string"

import { Layout, PostView, HeaderIndex, Banner } from 'gatsby-theme-try-ghost/src/components/common' // Medeinos: added banner component import
import { StickyNavContainer, OverlayContainer } from 'gatsby-theme-try-ghost/src/components/common/effects'
import { MetaData } from 'gatsby-theme-try-ghost/src/components/common/meta'

import { GlobalStateContext } from "gatsby-theme-try-ghost/src/context/GlobalState"

/**
* Main index page (home page)
*
* Loads all posts from Ghost
*
*/
const Index = ({ data, location, pageContext }) => {
    const posts = data.allGhostPost.edges
    const parsedQuery = location.search && location.search.length > 0 && queryString.parse(location.search)

    // Medeinos: check if homepage
    var katalogas = false
    var heading = 'Augalų katalogas'
    if (posts[0] && posts[0].node.tags[1]) {
      heading = posts[0].node.tags[1].name
    }
    if (location.pathname.includes("/augalu-katalogas")) {
      katalogas = true
    }

    return (
        <GlobalStateContext.Consumer>{ g => (
            <React.Fragment>
                <MetaData location={location} image={data.file} />
                <StickyNavContainer throttle={300} activeClass="fixed-nav-active" render={ sticky => (
                    <OverlayContainer render={ overlay => (
                        <Layout parsedQuery={parsedQuery !== '' ? parsedQuery : undefined } isHome={true} header={<HeaderIndex overlay={overlay}/>} sticky={sticky} overlay={overlay} >
                            {!katalogas ? ( /* Medeinos: added banners hidden on catalog */
                              <div className="inner">
                                <div className="banners">
                                  <Banner
                                    url="/augalu-katalogas/a"
                                    image="katalogas-baneris.jpg"
                                    title="Augalų Katalogas"
                                  />
                                  <Banner
                                    url="https://krautuvele.medeinos.lt"
                                    image="krautuvele-baneris.jpg"
                                    title="Medeinos Krautuvėlė"
                                  />
                                </div>
                              </div>
                            ) : ( 
                              <div className="inner">
                                <div className="inner catalog-header-content">
                                  <h1 className="site-title">{heading}</h1>
                                </div>
                                <nav className="catalog">
                                  <ul className="catalog-nav" role="menu">
                                    <li role="menuitem"><Link to='/augalu-katalogas/a/'>A, Ą</Link></li>
                                    <li role="menuitem"><Link to='/augalu-katalogas/b/'>B</Link></li>
                                    <li role="menuitem"><Link to='/augalu-katalogas/c/'>C, Č</Link></li>
                                    <li role="menuitem"><Link to='/augalu-katalogas/d/'>D</Link></li>
                                    <li role="menuitem"><Link to='/augalu-katalogas/e/'>E, Ę, Ė</Link></li>
                                    <li role="menuitem"><Link to='/augalu-katalogas/f/'>F</Link></li>
                                    <li role="menuitem"><Link to='/augalu-katalogas/g/'>G</Link></li>
                                    <li role="menuitem"><Link to='/augalu-katalogas/h/'>H</Link></li>
                                    <li role="menuitem"><Link to='/augalu-katalogas/i/'>I, Į</Link></li>
                                    <li role="menuitem"><Link to='/augalu-katalogas/y/'>Y</Link></li>
                                    <li role="menuitem"><Link to='/augalu-katalogas/j/'>J</Link></li>
                                    <li role="menuitem"><Link to='/augalu-katalogas/k/'>K</Link></li>
                                    <li role="menuitem"><Link to='/augalu-katalogas/l/'>L</Link></li>
                                    <li role="menuitem"><Link to='/augalu-katalogas/m/'>M</Link></li>
                                    <li role="menuitem"><Link to='/augalu-katalogas/n/'>N</Link></li>
                                    <li role="menuitem"><Link to='/augalu-katalogas/o/'>O</Link></li>
                                    <li role="menuitem"><Link to='/augalu-katalogas/p/'>P</Link></li>
                                    <li role="menuitem"><Link to='/augalu-katalogas/r/'>R</Link></li>
                                    <li role="menuitem"><Link to='/augalu-katalogas/s/'>S, Š</Link></li>
                                    <li role="menuitem"><Link to='/augalu-katalogas/t/'>T</Link></li>
                                    <li role="menuitem"><Link to='/augalu-katalogas/u/'>U, Ų, Ū</Link></li>
                                    <li role="menuitem"><Link to='/augalu-katalogas/v/'>V</Link></li>
                                    <li role="menuitem"><Link to='/augalu-katalogas/z/'>Z, Ž</Link></li>
                                  </ul>
                                </nav>
                              </div>
                            )
                            }
                            {!katalogas ? ( /* Medeinos: change layout for catalog */
                              <PostView globalState={g} pageContext={pageContext} posts={posts} isHome={true} />
                            ) : (
                              <PostView globalState={g} pageContext={pageContext} posts={posts} />
                            )
                            }             
                        </Layout>
                    )}/>
                )}/>
            </React.Fragment>
        )}
        </GlobalStateContext.Consumer>
    )
}

Index.propTypes = {
    data: PropTypes.shape({
        allGhostPost: PropTypes.object.isRequired,
        file: PropTypes.object,
    }).isRequired,
    location: PropTypes.object.isRequired,
    pageContext: PropTypes.object.isRequired,
}

export default Index

// This page query loads all posts
// Note that sorting within $postIds is irrelevant!
export const pageQuery = graphql`
  query GhostPostQueryShadowed($postIds: [String!]!, $limit: Int!, $skip: Int!) {
    allGhostPost(
        filter: {id: { in: $postIds }},
        limit: $limit,
        skip: $skip,
        sort: { fields: [featured, published_at], order: [DESC, DESC] }
    ) {
      edges {
        node {
          ...GhostPostFieldsForIndex
        }
      }
    }
    file(relativePath: {eq: "site-meta.png"}) {
      publicURL
        imageMeta {
            width
            height
      }
    }
  }
`